var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{staticClass:"af-card",on:{"submit":function($event){$event.preventDefault();return _vm.doLogin.apply(null, arguments)}}},[_c('span',{staticClass:"has-text-grey-icon"},[_vm._v(_vm._s(_vm.$t("af:login.title"))+" ")]),_c('p',{staticClass:"is-size-3 has-text-weight-bold"},[_vm._v(_vm._s(_vm.$t("af:login.subtitle")))]),_c('validation-observer',{ref:"loginObserver"},[(_vm.mode === 'email')?[_c('ValidationProvider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validated = ref.validated;
var valid = ref.valid;
return [_c('b-field',{staticClass:"mb-5",attrs:{"label":_vm.$t('af:login.input.email.label'),"label-position":"on-border","type":{ 'is-danger': errors[0], 'is-success': validated && valid },"message":errors}},[_c('b-input',{attrs:{"id":"LoginInputEmail"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1)]}}],null,false,905615831)})]:[_c('p',{staticClass:"nonce-info has-text-grey-icon"},[_vm._v(" "+_vm._s(_vm.$t("af:login.email.subtitle"))+" ")]),_c('phone-input',{attrs:{"id":"LoginInputPhone","label":_vm.$t('af:login.input.phone.label')},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})],(_vm.showPasswordInput)?[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validated = ref.validated;
var valid = ref.valid;
return [_c('b-field',{staticClass:"mb-5",attrs:{"label":_vm.$t('af:login.input.password.label'),"label-position":"on-border","type":{ 'is-danger': errors[0], 'is-success': validated && valid },"message":errors}},[_c('b-input',{attrs:{"id":"LoginInputPassword","type":"password","password-reveal":"","autocomplete":"new-password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c('div',{staticClass:"has-text-right forgot-password"},[_c('router-link',{staticClass:"has-text-grey-icon",attrs:{"id":"forgottenPasswordLink","to":"/forgot-password"}},[_vm._v(_vm._s(_vm.$t("af:login.button.forgotten_password")))])],1)]}}],null,false,2546438851)})]:_vm._e()],2),_c('b-field',{staticClass:"mt-4"},[_c('b-button',{attrs:{"id":"LoginButtonSubmit","type":"is-blue","native-type":"submit","loading":_vm.loginLoading,"expanded":""}},[_vm._v(_vm._s(_vm.$t("af:login.button.login")))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }