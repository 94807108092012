<template>
  <form class="af-card" @submit.prevent="doLogin">
    <span class="has-text-grey-icon">{{ $t("af:login.title") }} </span>
    <p class="is-size-3 has-text-weight-bold">{{ $t("af:login.subtitle") }}</p>

    <validation-observer ref="loginObserver">
      <template v-if="mode === 'email'">
        <ValidationProvider
          rules="required|email"
          v-slot="{ errors, validated, valid }"
        >
          <b-field
            :label="$t('af:login.input.email.label')"
            label-position="on-border"
            class="mb-5"
            :type="{ 'is-danger': errors[0], 'is-success': validated && valid }"
            :message="errors"
          >
            <b-input id="LoginInputEmail" v-model="email"></b-input>
          </b-field>
        </ValidationProvider>
      </template>

      <template v-else>
        <p class="nonce-info has-text-grey-icon">
          {{ $t("af:login.email.subtitle") }}
        </p>

        <phone-input
          id="LoginInputPhone"
          :label="$t('af:login.input.phone.label')"
          v-model="phone"
        />
      </template>

      <template v-if="showPasswordInput">
        <ValidationProvider
          rules="required"
          v-slot="{ errors, validated, valid }"
        >
          <b-field
            :label="$t('af:login.input.password.label')"
            label-position="on-border"
            class="mb-5"
            :type="{ 'is-danger': errors[0], 'is-success': validated && valid }"
            :message="errors"
          >
            <b-input
              id="LoginInputPassword"
              type="password"
              password-reveal
              v-model="password"
              autocomplete="new-password"
            ></b-input>
          </b-field>

          <div class="has-text-right forgot-password">
            <router-link
              id="forgottenPasswordLink"
              class="has-text-grey-icon"
              to="/forgot-password"
              >{{ $t("af:login.button.forgotten_password") }}</router-link
            >
          </div>
        </ValidationProvider>
      </template>
    </validation-observer>

    <b-field class="mt-4">
      <b-button
        id="LoginButtonSubmit"
        type="is-blue"
        native-type="submit"
        :loading="loginLoading"
        expanded
        >{{ $t("af:login.button.login") }}</b-button
      >
    </b-field>
  </form>
</template>

<script>
import i18n from "@/plugins/i18n";
import { mapGetters } from "vuex";
import { generateNonce } from "@/utils/util";
import { APPLICATION_MODE, LOGIN_STATUS, UI_CONFIGS } from "@/utils/const";
import { resetValidator } from "@/utils/util";
import {
  clearLandingParams,
  getActiveLandingParams,
} from "@/utils/landingParamUtil";
import twoFactorMixin from "@/mixins/twoFactorMixin";

export default {
  name: "Login",
  title: "af:page.login.title",
  mixins: [twoFactorMixin],
  data() {
    return {
      mode: "", // email | phone
      nonce: generateNonce(),
      email: "",
      password: "",
      phone: "",
      loginLoading: false,
    };
  },
  components: {
    PhoneInput: () => import("@/components/PhoneInput.vue"),
  },
  computed: {
    ...mapGetters({
      loggedIn: "session/isReady",
      autoloadConfigValue: "config/getAutoloadConfigValue",
    }),
    showPasswordInput() {
      return !(
        this.mode === "phone" &&
        this.autoloadConfigValue(UI_CONFIGS.PASSWORDLESS) === "true"
      );
    },
    mortgageExternalId() {
      let landingParams = getActiveLandingParams();
      return landingParams
        ? JSON.parse(landingParams).mortgageExternalId
        : null;
    },
  },
  created() {
    this.mode = this.$route.meta.mode;
    if (this.$route.params.userId) {
      this.executeFirstLogin(this.$route.params.userId); //externally created user's first login
    }
  },
  async mounted() {
    await resetValidator(this.$refs.loginObserver);

    if (this.loggedIn) {
      this.$router.push("/user/profile");
      return;
    }
    window.setTimeout(() => {
      if (this.loggedIn) {
        this.$router.push("/user/profile");
      }
    }, 1500);
  },
  methods: {
    async doLogin() {
      try {
        const valid = await this.$refs.loginObserver.validate();
        if (!valid) return;

        this.track();

        this.loginLoading = true;
        let login = {
          user: this.mode === "email" ? this.email : this.phone,
          password: this.password,
          nonce: this.nonce,
        };

        login.mortgageExternalPrelimId = this.mortgageExternalId;

        let loginResponse = await this.$store.dispatch("session/login", login);
        let bypass = !!loginResponse.bypass;

        if (!bypass) {
          await this.$store.dispatch("session/saveSecondFactor", {
            id: loginResponse.secondFactorResponse.secondFactorId,
            nonce: this.nonce,
            status: loginResponse.status,
            userId: loginResponse.secondFactorResponse.userId,
          });
        }

        if (
          loginResponse.status === LOGIN_STATUS.ENUM.REGISTRATION_INCOMPLETE
        ) {
          await this.$router.push(
            loginResponse.currentApplicationMode === APPLICATION_MODE.MORTGAGE
              ? "/mortgage/registration/second-factor"
              : "/registration/second-factor"
          );
        } else if (loginResponse.status === LOGIN_STATUS.ENUM.LOGIN_OK) {
          await this.$router.push("/login/second-factor");
        } else if (
          loginResponse.status === LOGIN_STATUS.ENUM.PASSWORD_CHANGE_REQUIRED
        ) {
          await this.$router.push("/forgot-password");
        } else if (bypass) {
          let redirect = await this.bypassTwoFactor(loginResponse);
          await this.$router.push(redirect);
          clearLandingParams();
        }
        this.loginLoading = false;
      } catch (ex) {
        console.warn(ex);
        this.loginLoading = false;
        this.$buefy.toast.open({
          message: i18n.t("af:login.error.general"),
          type: "is-danger",
        });
      }
    },
    async executeFirstLogin(userId) {
      try {
        this.track();
        this.loginLoading = true;
        let login = {
          user: userId,
          nonce: this.nonce,
        };
        let loginResponse = await this.$store.dispatch(
          "session/externallyCreatedUsersFirstLogin",
          login
        );

        await this.$store.dispatch("session/saveSecondFactor", {
          id: loginResponse.loginStatusResponse.secondFactorResponse
            .secondFactorId,
          nonce: this.nonce,
          status: loginResponse.loginStatusResponse.status,
          customerFirstName: loginResponse.customerFirstName,
          firstLogin: true,
          userId: userId,
        });

        if (
          loginResponse.loginStatusResponse.status ===
          LOGIN_STATUS.ENUM.LOGIN_OK
        ) {
          this.$router.push("/registration/second-factor");
        }

        this.loginLoading = false;
      } catch (ex) {
        console.warn(ex);
        this.loginLoading = false;
        this.$buefy.toast.open({
          message: i18n.t("af:login.error.general"),
          type: "is-danger",
        });
      }
    },
    track() {
      this.$gtag.event("login");
    },
  },
};
</script>

<style scoped lang="scss">
.af-card {
  background: white;
  border-radius: 16px;
  padding: 20px;
}

.forgot-password {
  margin-top: -1rem;
  font-size: 14px;
}

.nonce-info {
  margin-top: -1rem;
  margin-bottom: 2rem !important;
  max-width: 75%;
}

.mbh {
  .af-card {
    border-radius: 32px;
  }
}

@media only screen and (min-width: 769px) {
  .af-card {
    padding: 32px 36px;
  }
}
</style>
